<template>
    <div>
        <div class="vx-card p-8">
            <div class="flex flex-row gap-2">
               <p class="font-bold">{{ $t('shops.username') }}:</p>
               <p>{{ user.username }}</p>
            </div>
            <div class="flex flex-row gap-2">
                <p class="font-bold">{{ $t('shops.id') }}:</p>
                <p>{{ user.id }}</p>
            </div>
            <div class="flex flex-row gap-2">
                <p class="font-bold">Uuid:</p>
                <p>{{ user.uuid }}</p>
            </div>
            <div class="flex flex-row gap-2">
                <p class="font-bold">{{ $t('shops.email') }}:</p>
                <p>{{ user.email }}</p>
            </div>
            <div class="flex flex-row gap-2">
                <p class="font-bold">{{ $t('admin.users.form.firstname') }}:</p>
                <p>{{ user.firstname ? user.firstname : '-' }}</p>
            </div>
            <div class="flex flex-row gap-2">
                <p class="font-bold">{{ $t('admin.users.form.lastname') }}:</p>
                <p>{{ user.lastname ? user.lastname : '-' }}</p>
            </div>
            <div class="flex flex-row gap-2">
                <p class="font-bold">{{ $t('admin.challenges.settings.community') }}:</p>
                <p>{{ communityName }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/modules/Shared/Store/store";
import UsersService from "@/modules/Admin/Services/UsersService";
import {unifyPaginatedResponse} from "@/modules/Shared/Helpers/apiResponseHelper";
import CommunityService from "@/modules/Admin/Services/CommunityService";

export default {
  name: "ClientUserDetailPage",
  components: {},

  data() {
    return {
      user: null,
      communities: [],
    }
  },
  async beforeRouteEnter (to, from, next) {
      const user = await UsersService.getUser(to.params.uuid);
      const communities = await unifyPaginatedResponse(CommunityService.getCommunities)

      await store.dispatch('setRouteElement', user)
      next(vm => {
          vm.user = user
          vm.communities = communities
      })
  },
  computed: {
      communityName() {
          return this.communities.find(c => c.uuid === this.user.community_uuid).name
      }
  },
};
</script>
